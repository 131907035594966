<template>
  <el-dialog
    title="调用数字码"
    :close-on-click-modal="false"
    :visible.sync="visible"
    append-to-body
    class="missionPart"
  >
    <el-form
      :model="dataForm"
      :rules="dataRule"
      ref="dataForm"
      @keyup.enter.native="dataFormSubmit()"
      label-width="80px"
      class="tablePart"
    >
      <el-form-item label="数字码" prop="digitalCode">
        <el-input
          v-model="dataForm.digitalCode"
          placeholder="统一社会信用代码"
        ></el-input>
      </el-form-item>
      <el-tooltip
        class="item"
        effect="dark"
        placement="top"
        style="line-height: 27px"
      >
        <i class="el-icon-question" />

        <div style="width: 200px" slot="content">
          在智能取餐柜端调用的数字码，可以是仓位码、开锁码和重置码
        </div>
      </el-tooltip>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取消</el-button>
      <el-button type="primary" @click="dataFormSubmit()">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
      dataForm: {
        digitalCode: '',
        machineCode: '',
      },
      dataRule: {
        digitalCode: [
          { required: true, message: '数字码名字不能为空', trigger: 'blur' },
        ],
      },
    };
  },
  methods: {
    init(machineCode) {
      this.visible = true;
      this.$nextTick(() => {
        this.$refs['dataForm'].resetFields();
        this.dataForm.machineCode = machineCode;
      });
    },
    // 表单提交
    dataFormSubmit() {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          this.$http({
            url: `/cc/freezer/call-code`,
            method: 'post',
            data: {
              digitalCode: this.dataForm.digitalCode,
              machineCode: this.dataForm.machineCode,
            },
          }).then(({ data }) => {
            if (data && data.status === 0) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1500,
              });
              this.visible = false;
              this.$emit('refreshDataList');
            }
          });
        }
      });
    },
  },
};
</script>
